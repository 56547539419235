import * as React from "react"
import { Helmet } from 'react-helmet'
import { PageProps } from "gatsby"
import Menu from "../components/Menu";

// @ts-ignore
import collectionNature from "../images/collection-nature.webp"
// @ts-ignore
import collectionReality from "../images/collection-reality.webp"

import GalleryImage from "../components/GalleryImage";
import Footer from "../components/Footer";
import Card from "../components/Card";

const Index: React.FC<PageProps> = () => {
  return (
    <div className="min-h-screen">
      {/* @ts-ignore */}
      <Helmet title="Elbae">
        <script src="https://rawcdn.githack.com/nextapps-de/spotlight/0.7.8/dist/spotlight.bundle.js"></script>
      </Helmet>
      
      <Menu />
      <main className="flex flex-wrap flex-row mt-24">
        <Card centered backgroundImage={collectionReality} to="/collection/distortion-reality">
            <h1 className="font-superclarendon text-4xl mb-6">
                The Distortion of Reality
            </h1>
        </Card>
        <Card centered backgroundImage={collectionNature} to="/collection/distortion-nature">
            <h1 className="font-superclarendon text-4xl mb-6">
                The Distortion of Nature; water
            </h1>
        </Card>
      </main>

      <Footer />
    </div>
  );
}

export default Index
